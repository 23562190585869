import * as React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"


const Consultation = () => (
  <Container className="singleCol">
    <p class="text-center"><strong>At Marin County Remodel, every project starts with a FREE, one hour consultation.</strong><br />
  To schedule one today, call Jim at (415) 825-0350 ​or submit your <Link to="/contact/">contact information here</Link>.</p>
  </Container>
)

export default Consultation
