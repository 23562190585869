import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container } from "react-bootstrap"

// import { StaticImage } from "gatsby-plugin-image"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="Frequently Asked Questions" />
    <Container className="singleCol">
    <h1 className="text-center mb-4">Frequently Asked Questions</h1>
    
    <h2>How much is this going to cost?</h2>
    <p>The cost will depend on the masterplan that we develop together.  After a good plan is in place,  I can give an order-of-magnitude estimate for both “hard” construction costs and “soft” costs for design fees, building permit fees, and owner contingencies. Furniture and appliances might also need to be budgeted.</p>

    <h2>How long will this take?</h2>
    <p>I will outline likely durations for choosing a contractor, working out all the important design details with you, completing documents for permit submission, permit review times, correction and resubmission based on review comments, contractor final pricing with his subcontractors, and construction.</p>

    <h2>How do I know that our plans are legal?</h2>
    <p>An early step is always to check City Planning Codes and to schedule a meeting (if possible) with local Planning.  If proposed changes include issues that might trigger Building Department and/or Fire District concerns, I will also contact those jurisdictions. </p>

    <h2>How can I find a good building contractor?</h2>
    <p>I have a list of contractors who’ve completed good work on time and within budget for former clients. But no contractor can give you a reliable cost and a firm schedule without good information. That’s why I recommend adding estimating notes to the masterplan and sending the information to several reliable contractors.</p>

    <h2>Do I need a structural engineer or a heating/cooling specialist?</h2>
    <p>For all but the most complex multi-story seismic issues, I do my own structural plans and get permits. I assess whether electrical service needs to be upgraded, and I’m familiar with heating/cooling options.</p>

    <h2>What should I watch out for? What are the most common pitfalls?</h2>
    <p>Signing up a reliable builder is the most difficult part of the process because good people are often tied up for months ahead.  Beware of any contractor who claims to give you a price before fully investigating your house and your goals.</p>
    <hr />
    </Container>

    <div className="mt-3">
      <Consultation  />
    </div>
  </Layout>
)

export default SecondPage
